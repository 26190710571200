import './TreatmentTooltip.scss';

import { ComponentProps, FunctionComponent, ReactElement } from 'react';

import { Tooltip, TooltipProps } from '../../../../../../components';
import { Color } from '../../../../../../theme/colors';

const TREATMENT_TOOLTIP_WIDTH_IN_PX = 225;

export enum TooltipType {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  MODIFICATION = 'MODIFICATION',
}

interface TreatmentTooltipProps extends ComponentProps<'div'> {
  type: TooltipType;
  tooltipContent?: ReactElement;
  tooltipProps?: Omit<TooltipProps, 'title' | 'description' | 'children'>;
  borderColor?: Color;
}

export const TreatmentTooltip: FunctionComponent<TreatmentTooltipProps> = ({
  type,
  tooltipContent,
  tooltipProps,
  borderColor,
  ...props
}) => {
  const bubble = (
    <div
      {...props}
      style={{ ...(props.style ?? {}), borderColor }}
      className={`treatment-tooltip-${type} ${props.className ?? ''}`}
    />
  );

  return tooltipContent !== undefined ? (
    <Tooltip
      title={''}
      description={tooltipContent}
      position="top"
      width={TREATMENT_TOOLTIP_WIDTH_IN_PX}
      {...tooltipProps}
    >
      {bubble}
    </Tooltip>
  ) : (
    bubble
  );
};
